import Image from 'next/image';

import { MdChevronRight } from 'react-icons/md';

import { Avatar, Button, Col, Row, Stars, Tag } from 'darrius';

import clsx from 'clsx';

import { getBlurDataURL, imgPrefix, triggerEvent } from '../../../utils';

import { FlatCard, FlatCardBody } from '../../../components';

import { type TPro } from '../../../modules/instantMatch/transformations';

import styles from './ProCardDetail.module.scss';

type TDefaultProps = Omit<TPro, 'servicePrice' | 'tag' | 'isInstitutionalPro'>;

type IProProps = TDefaultProps & {
  index: number;
  onMessageClick?: (pro: unknown) => void;
  shouldShowChatButton?: boolean;
  initialMessage?: string;
  acceptedConversation?: string;
  chatLink?: string;
  leadId?: number;
  isDisabled?: boolean;
};

interface IMessageButtonProps {
  onClick: (pro: unknown) => void;
  pro: unknown;
  className?: string;
}

function MessageButton({ onClick, pro, className }: IMessageButtonProps) {
  const messageButtonClassName = clsx(className);

  return (
    <Button.Root
      size="small"
      variation="primary"
      onClick={() => onClick(pro)}
      className={messageButtonClassName}
      fullWidth
    >
      <Image
        src={imgPrefix('/images/pro-card/notification.svg')}
        alt=""
        aria-hidden="true"
        width="24"
        height={24}
      />
      Ver Mensagens
    </Button.Root>
  );
}

const Default = (props: IProProps) => {
  const {
    id,
    link,
    name,
    photo,
    rating,
    requestId,
    index,
    onMessageClick,
    shouldShowChatButton = false,
    isDisabled = false,
  } = props;

  const classes = clsx('col-sm-12', styles.card, {
    [styles['card--disabled']]: isDisabled,
  });

  return (
    <Col className={classes}>
      <FlatCard>
        <FlatCardBody>
          <Row className={styles.card__actions}>
            <Col
              className={`col-sm-6 ${shouldShowChatButton ? 'col-md-6' : 'col-md-10'}`}
            >
              <div className={styles.card__bio}>
                <Avatar
                  src={photo}
                  alt={name}
                  size={72}
                  fallback={{
                    name: name,
                    variant: 'byName',
                  }}
                />

                <div className={styles['card__bio-info']}>
                  <h3 data-testid="pro-card-name_without-verification">
                    <span
                      className={styles['card__name-nowrap']}
                    >{`${name}`}</span>
                  </h3>
                  <div
                    className={`${styles.card__rating} ${styles.rating__default}`}
                  >
                    <Stars rating={1} />

                    <p>{rating.value}</p>
                    <p
                      className={`${styles['card__amount-rating']}`}
                    >{`(${rating.count})`}</p>
                  </div>
                </div>
              </div>
            </Col>

            {shouldShowChatButton && (
              <Col
                className={`${styles['card__pro-message']} col-sm-12 col-md-4`}
              >
                <MessageButton onClick={onMessageClick} pro={props} />
              </Col>
            )}

            <Col className={`${styles['card__pro-view']} col-sm-6 col-md-2`}>
              <a
                href={link}
                className="start-sm"
                aria-label={name}
                data-testid="pro-card-link"
                onClick={() =>
                  triggerEvent('instant-match-profile-list__click-profile', {
                    profile: id,
                    requestId: requestId ? requestId : null,
                    index: index + 1,
                  })
                }
              >
                <div className="hide-sm">
                  <p>Ver perfil</p>
                </div>
                <div className={styles['card__arrow-icon']}>
                  <MdChevronRight size={24} />{' '}
                </div>
              </a>
            </Col>
          </Row>
        </FlatCardBody>
      </FlatCard>
    </Col>
  );
};

type TInstitutionalProps = Omit<
  TPro,
  'isInstitutionalPro' | 'tag' | 'servicePrice'
>;

interface IInstitutionalProProps extends TInstitutionalProps {
  servicePrice?: string;
  onMessageClick?: (pro: unknown) => void;
  shouldShowChatButton?: boolean;
  leadId?: number;
}
const Institutional = (props: IInstitutionalProProps) => {
  const {
    id,
    link,
    name,
    photo,
    rating,
    servicePrice,
    requestId,
    onMessageClick,
    shouldShowChatButton = false,
  } = props;

  return (
    <Col className={`col-sm-12 ${styles.card} ${styles['institutional-card']}`}>
      <FlatCard>
        <FlatCardBody>
          <div className="start-sm">
            <Row>
              <Col className="col-md-6 col-sm-10">
                <div className={styles.card__bio}>
                  <Avatar
                    src={photo}
                    alt={name}
                    size={72}
                    fallback={{
                      name: name,
                      variant: 'byName',
                    }}
                  />

                  <div className={styles['card__bio-info']}>
                    <h3 data-testid="pro-card-name_without-verification">
                      <span
                        className={styles['card__name-nowrap']}
                      >{`${name}`}</span>
                    </h3>
                    <div className={styles['institutional-card__tag']}>
                      <div className={styles.institutional__tag_specialist}>
                        <Tag className="action">Especialista</Tag>
                      </div>

                      <div>
                        <Tag className="action">Pagamento Seguro</Tag>
                      </div>
                    </div>
                    <div
                      className={`${styles.card__rating} ${styles.rating__default}`}
                    >
                      <Stars rating={1} />

                      <p>{rating.value}</p>
                      <p
                        className={`${styles['card__amount-rating']}`}
                      >{`(${rating.count})`}</p>
                    </div>
                  </div>
                </div>
              </Col>

              <Col className={`${styles.card__price} col-sm-2 col-md-6`}>
                {servicePrice ? (
                  <>
                    <Col
                      className={`${styles['card__price-text']} col-sm-5 col-md-12`}
                    >
                      <div className={styles['card__price-title']}>
                        <p>A partir de:</p>
                      </div>
                      <div className={styles['card__price-value']}>
                        <p>{servicePrice}</p>
                      </div>
                    </Col>
                    <Col className="col-sm-7 col-md-12">
                      <div>
                        <Button.Link
                          size="small"
                          variation="primary"
                          href={link}
                          data-testid="institutional-card-link"
                          onClick={() =>
                            triggerEvent(
                              'instant-match-profile-list__click-institutional-profile',
                              {
                                profile: id,
                                requestId: requestId ? requestId : null,
                                price: servicePrice,
                              }
                            )
                          }
                          fullWidth
                        >
                          Ver indicação
                        </Button.Link>
                      </div>
                    </Col>
                  </>
                ) : (
                  <Row className={styles.card__actions_nowrap}>
                    {shouldShowChatButton && (
                      <Col className={`col-sm-12 col-md-8 hide-sm`}>
                        <MessageButton
                          pro={props}
                          onClick={onMessageClick}
                          className={styles.actions__container__profile}
                        />
                      </Col>
                    )}

                    <Col
                      className={`${styles.card__actions} col-sm-12 ${shouldShowChatButton ? 'col-md-4' : 'col-md-12'}`}
                    >
                      <a
                        href={link}
                        className={styles.profile__link}
                        data-testid="institutional-card-link-without-price"
                        onClick={() =>
                          triggerEvent(
                            'instant-match-profile-list__click-institutional-profile',
                            {
                              profile: id,
                              requestId: requestId ? requestId : null,
                            }
                          )
                        }
                      >
                        <div className="hide-sm">
                          <p>Ver perfil</p>
                        </div>
                        <div className={styles['card__arrow-icon']}>
                          <MdChevronRight size={24} />{' '}
                        </div>
                      </a>
                    </Col>
                  </Row>
                )}
              </Col>

              {shouldShowChatButton && (
                <Col className={'col-md-12 hide-md'}>
                  <MessageButton
                    pro={props}
                    onClick={onMessageClick}
                    className={styles.actions__container__profile}
                  />
                </Col>
              )}
            </Row>
          </div>

          <hr className={styles.line}></hr>

          <Row className={`${styles.card__status}`}>
            <Col className={`${styles.card__online} col-sm-5`}>
              <span className={styles['card__online-img']}>
                <Image
                  src={imgPrefix(
                    '/images/contato-instantaneo/img_instant-matching-online.svg'
                  )}
                  width="18"
                  height="18"
                  alt="Online agora"
                  placeholder="blur"
                  blurDataURL={getBlurDataURL()}
                  priority
                />
              </span>
              <span>Online agora</span>
            </Col>
            <Col className={`${styles['card__answer-time']} col-sm-7`}>
              <span>Responde em cerca de 1h</span>
            </Col>
          </Row>
        </FlatCardBody>
      </FlatCard>
    </Col>
  );
};

export { Default, Institutional };
